import React, { useContext, useState } from "react";
import axios from "axios"; // Make sure to import axios
import "./style.css";
import Button from "../components/Button";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";


const NumberVerification = () => {
  const [value, setValue] = useState();
  const [selectedYear, setSelectedYear] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();


  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!selectedYear || !isValidPhoneNumber(value)) {
      // Perform validation checks
      console.log("Invalid input. Please check your selections.");
      setErrorMessage("Please select a valid year and phone number.");
      return
    
      
      ;
    }

    const emailid = currentUser.email;
    const phoneNumber = value && formatPhoneNumberIntl(value);
    const username = currentUser.displayName;
    const picture = currentUser.photoURL;
    const details = { emailid, username, picture, phoneNumber, selectedYear };
    const requestData = { message: details };
    console.log(requestData)

    try {
      const response = await axios.post(
        '/api/store_verified_user',
        details
      );
      console.log(response.data.message);

      if (response.data.message === "true") {
        // Navigate to "/" page on success
        navigate("/");
        console.log("Data stored successfully.");
      } else {
        console.log("Data storage failed.");
      }
    } catch (error) {
      console.error("Error storing user data:", error);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  if (!currentUser) {
    return <div>Please log in to see your avatar.</div>;
  }

  return (
    <div className="loginContainer">
      <div className="NumberContainerContent">
        <img className="photo" src={currentUser.photoURL} alt="User Avatar" />
        <h1>Phone Number Verification</h1>
        <p>Your AI Assistant</p>
        <div className="number123">
        <form onSubmit={handleFormSubmit}>
          <div className="otherQuestionsWrapper">
            <label className="formtext" htmlFor="yearSelect">
              Select Year
            </label>
            <select
              id="yearSelect"
              className="dropdown"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="" disabled>
                Select a year
              </option>
              <option value="First Year">First year</option>
              <option value="Second Year">Second Year</option>
              <option value="Third Year">Third Year</option>
              <option value="Final Year">Final Year</option>
            </select>
          </div>
          <PhoneInput
            placeholder="Enter phone number"
            international
            value={value}
            defaultCountry="IN"
            onChange={setValue}
          />
          
          <Button  text="Submit"  />
          </form>
          </div>
          {errorMessage && <div className="error">{errorMessage}</div>}
       
      </div>
    </div>
  );
};

export default NumberVerification;
