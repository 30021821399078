import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import Loading from "../components/Loading";
import SvgComponent from "../components/SvgComponent";
import Avatar_ECD from "../components/Whatsapp ShareFor ECD";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [inputPrompts, setInputPrompts] = useState([]);
  const [events, setEvents] = useState([]);
  const [sseConnection, setSseConnection] = useState(null);
  const [question, setQuestion] = useState([]);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [fetchInput, setFetchInput] = useState([]);
  const [hasRelatedQuestion, setHasRelatedQuestion] = useState([false]);
  const [showRelatedQuestions, setShowRelatedQuestions] = useState(false);
  const [newInputPrompt, setNewInputPrompt] = useState("");
  const textareaRef = useRef(null);
  const chatLogRef = useRef(null);
  const [showFeedback, setShowFeedback] = useState(false);


  const toggleRelatedQuestions = () => {
    setShowRelatedQuestions((prev) => !prev);
  };

  async function callAPI() {
    let firstEmptyContentEncountered = false;
   setShowFeedback(false)
    // Establish new SSE connection with the inputPrompt
    const urlEncoded = `/sse?query=${encodeURIComponent(
      inputPrompt + newInputPrompt
    )}`;
    const newSseConnection = new EventSource(urlEncoded);

    // Handle SSE messages
    newSseConnection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const content = data.content.replace(/\n/g, "<br/>");

      if (!firstEmptyContentEncountered && content === "") {
        // Skip the first empty content
        firstEmptyContentEncountered = true;
      } else if (content !== "") {
        // Add non-empty content to events
        setEvents((prevEvents) => [...prevEvents, content]);
      } else {
        // Close the SSE connection after processing the second empty content
        newSseConnection.close();
        setShowFeedback(true)
        setChatLog([
          ...chatLog,
          {
            chatPrompt: inputPrompt,
            botMessage: data.botResponse,
          },
        ]);
      }
    };

    // Handle SSE errors
    newSseConnection.onerror = (error) => {
      console.error("EventSource failed:", error);
      newSseConnection.close(); // Close the SSE connection in case of an error
    };

    // Set the new SSE connection
    setSseConnection(newSseConnection);
    setInputPrompt("");
    setNewInputPrompt("");

    //  Set responseFromAPI back to false after the fetch request is complete
    setReponseFromAPI(false);
  }

  const handleQuestionClick = (clickedQuestion) => {
    setNewInputPrompt(clickedQuestion);
  };

  useEffect(() => {
    if (newInputPrompt.trim() !== "") {
      console.log(newInputPrompt);
      setEvents([]);
      setQuestion(newInputPrompt);
      setShowRelatedQuestions(false);
      callAPI();
    }
  }, [newInputPrompt]);

  const fetchQuestions = async () => {
    try {
      const response = await fetch("/api/inputmsgs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputPrompt, name: "", email: "" }),
      });
     
      console.log(inputPrompt);
      setFetchInput(inputPrompt);

      const data = await response.json();
      // Set all questions and reset startIndex
      console.log(data)
      setDisplayedQuestions(data.botResponse);
      if (displayedQuestions.length > 0) {
        setHasRelatedQuestion(true);
      } else {
        setHasRelatedQuestion(false);
      }
      setStartIndex(0);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEvents([]);
    setShowRelatedQuestions(false);

    if (sseConnection) {
      sseConnection.close();
    }

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        setFetchInput(inputPrompt);
        setQuestion(inputPrompt);
        fetchQuestions();
        callAPI();
        setInputPrompt("");

        // hide the keyboard in mobile devices
        e.target.querySelector("input").blur();
      }
    }
  };

  const handleShowMore = () => {
    const newStartIndex = startIndex + 5;
    if (newStartIndex < displayedQuestions.length) {
      setStartIndex(newStartIndex);
    }
  };

  const handleShowPrevious = () => {
    const newStartIndex = Math.max(0, startIndex - 5);
    setStartIndex(newStartIndex);
  };
  const questionsToDisplay = displayedQuestions.slice(
    startIndex,
    startIndex + 5
  );

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [events]);

  // Clean up SSE connection on component unmount
  useEffect(() => {
    return () => {
      if (sseConnection) {
        sseConnection.close();
      }
    };
  }, [sseConnection]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to the scroll height
    }
  }, [inputPrompt]);


  

  return (
    <>
      <div className="mainitem">
        <div className="inputbox_type_ECD">
          <div className="inputPromptWrapper_ECD">
            <div className="inputPrompttTextarea_ECD">
              <textarea
                ref={textareaRef}
                name="inputPrompt"
                className="input_area_ECD"
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                placeholder="Type Your Doubts here"
              ></textarea>
            </div>
            <button
              className="sentbutton_ECD"
              aria-label="form submit"
              type="submit"
              onClick={handleSubmit}
            >
              <img src="/9349901.png" alt="Logo" id="searchIcon" />
            </button>
          </div>

          {question.length > 0 && (
            <div className="Query_Wrapper_ECD">
              <div className="chatPromptMainContainer_ECD">
                <div className="chatPromptWrapper_ECD">
                  <div className="chatPrompt_ECD">{question}</div>
                </div>
                {questionsToDisplay.length > 0 && (
                  <div
                    className="showRelatedButton_ECD"
                    onClick={toggleRelatedQuestions}
                  >
                    {showRelatedQuestions
                      ? "Hide Related Questions"
                      : "Show Related Questions"}
                  </div>
                )}
              </div>
            </div>
          )}

          {showRelatedQuestions && questionsToDisplay.length > 0 && (
            <div className="relatedSection_ECD">
              <div
                className={`relatedHeading_ECD${
                  questionsToDisplay.length > 0 ? "" : "hide"
                }`}
              >
                Related Questions
              </div>
              <div className="relatedQuestionsWrapper_ECD">
                {questionsToDisplay.map((question) => (
                  <div
                    className="questions_ECD"
                    key={question.QuestionNumber}
                    onClick={() => handleQuestionClick(question.Question)}
                  >
                    <p className="questionRelated_ECD">{question.Question}</p>
                    <p className="subjectName_ECD">{question.Subject}</p>
                  </div>
                ))}
              </div>
              <div className="buttonRelatedWrapper_ECD">
                {startIndex > 0 && (
                  <div
                    className="relatedButton_ECD"
                    onClick={handleShowPrevious}
                  >
                    Show Previous
                  </div>
                )}

                {questionsToDisplay.length === 5 && (
                  <div className="relatedButton_ECD" onClick={handleShowMore}>
                    Show More
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <section className="chatBox">
          {question.length > 0 ? (
            <div className="chatLogWrapper" id="chatLogWrapper">
              <div className="chatLog">
                <div className="botMessageMainContainer" ref={chatLogRef}>
                  <div className="botMessageWrapper">
                    <Avatar className="userSVG">
                      <SvgComponent w={41} h={41} />
                    </Avatar>
                    {events.length > 0 ? (
                      <div id="botMessage">
                        <p
                          dangerouslySetInnerHTML={{ __html: events.join("") }}
                        ></p>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="chatLogWrapper">
              <IntroSection />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Home;
