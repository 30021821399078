import React, {useContext} from "react";
import Typewriter from 'typewriter-effect';
import GridLoader from "react-spinners/PulseLoader";



const Loading = () => {
  const override = {
    color: "#fff",
    loading: true,
  };




  return (
    <div>
   
      <div>
      <Typewriter
        options={{
          strings: [
            `Analysing the question in detail`,
            'Searching through a vast library of information to find the answer you need',
            `We are crafting best response tailored just for you`,
            `Your question is important to me, and I'm committed to delivering a helpful answer`,
            'Personalising the answer for you'
          ],
          autoStart: true,
          loop: true,
  }}
/>
      </div>
      <GridLoader
        color={override.color}
        loading={override.loading}
        cssOverride={override}
        size={5}
        aria-label="Loading Grid"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;

