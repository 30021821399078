import React, { useState, useEffect } from 'react'; 
import "./normal.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import InApp from './pages/Single Page';
import LoginForm from "./components/login/LoginForm";
import { Navigate, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import NumberVerification from "./components/Numberverification";
import "./App.css"




function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="auth/login" />;
  };
  
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);




  return (
    <div className="App">
      <Routes>
        <Route
          index
          exact
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
      
        <Route exact path="auth/login" element={<Login />} />
        <Route exact path="login" element={<Login />} />
        <Route exact path="numberverification" element={<NumberVerification />} /> {/* Add this route */}
        <Route exact path ="ecdentistry-dentalAI" element={<InApp />} />
    
      </Routes>
    </div>
  );
}

export default App;

