import React, { useState, useContext} from "react";
import Button from "../components/Button";
import { auth, goggleAuthProvider } from "../firebase.config";
import SignupForm from "../components/signup/SignUpForm";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { signInWithPopup } from "firebase/auth";
import axios from 'axios';

const Login = () => {
  const [isSignupFormVisible,] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSignInWithGoggle = async () => {
    try {
      let userCredential = await signInWithPopup(auth, goggleAuthProvider);
      const user = userCredential.user;
      dispatch({ type: "LOGIN", payload: user });
      console.log("user", user);
      const emailid= user.email
      const username= user.displayName
      const picture= user.photoURL
      const details = { emailid, username, picture };
      const requestData = { message: details };
      console.log(JSON.stringify(requestData));
      
      try {
        const response = await axios.post('http://127.0.0.1:5000/api/store_user_data', details);
        console.log(response.data.message);

        if (response.data.message === "true") {
          navigate("/");
        } else if (response.data.message === "false") {
          navigate("/numberverification");
        }
      } catch (error) {
        console.error("Error storing user data:", error);
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  return (
   
    <div className="loginContainer">

      <div className="loginContainerContent">
      <img
        src="/Logo.png"
        alt="Buy Me A Coffee Widget"
        className="loginimage"
      
      /> 
        <h1>Welcome to Dental AI</h1>
        <p>Your AI Assistant</p>
        <div className="loginButtonWrapper">
          <Button text="Log in" handleClick={() => handleSignInWithGoggle("login")} />
        </div>
      </div>
      {isSignupFormVisible && <SignupForm />}
    </div>
  );
};

export default Login;
