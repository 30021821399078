import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext"; // Adjust the path accordingly
import NavLinks from "./NavLink";


const Avatar = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    // User is not logged in, show a placeholder avatar or login prompt
    return <div>Please log in to see your avatar.</div>;
  }

  // User is logged in
  const { displayName} = currentUser;

  return (
    <div className="avatar-wrapper">
    <div className="avatar-container1">
      <div className="avatar-button2">
        
        <div className="avatar-logo2">
        <img
          src="/Artboard 1_1.png"
          alt="Logo"
          id="dentalailogo"
        /></div>
         <div className="avatar-button3">
           <div className="hitext">Hi,</div>
        <div className="hitext2"> {displayName}</div>
      </div>
     
     
      </div>
      <div className="right-side-container">
      <a
        href="https://wa.me/?text=Hey%20buddy%2C%20quick%20share%21%20I%27ve%20been%20using%20Dental%20AI%20for%20my%20dental%20studies%20and%20it%27s%20pure%20gold.%0A%0AImagine%20having%20a%20super-smart%20dental%20mentor%20right%20in%20your%20pocket%20%E2%80%93%20that%27s%20Dental%20AI%20for%20you.%0A%0ATrust%20me%2C%20you%20won%27t%20regret%20diving%20into%20this%20smart%20learning%20journey.%20Let%27s%20conquer%20dental%20studies%20together%20with%20Dental%20AI%21%20%20Visit%20dentalai.in"
        target="_blank"
        rel="noreferrer"
        className="avatar-button1"
      >
        <img
          src="/WhatsApp.webp"
          alt="Logo"
          id="whatsapplogo"
          className="avatar-logo1"
        />
      </a>

     
      <div className="navLinks">
   

      <NavLinks
      
        svg={
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            style={{ marginLeft: "4px" }}
          >
            <path
              d="m16 17 5-5m0 0-5-5m5 5H9m0-9H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21H9"
              stroke="#000"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        text="Log out"
        link=""
      />
   
    </div>
        </div>
      </div>
    </div>
    
  );
};

export default Avatar;
