import React from "react";
import BotResponse from "./BotResponse";

const IntroSection = () => {
  return (
    <div id="introsection">
   
   <div className="avatar-logo2_ECD">
        <img
          src="/Artboard 1_1.png"
          alt="Logo"
          id="dentalailogo_ECD"
        /></div>
         <div className="avatar-logo3_ECD">
        <h1>
           Dental AI
</h1>    
    </div>
    </div>
  );
};

export default IntroSection;
