import React from "react";

const Error = ({ err }) => {
  return (
    <div className="errorMessage">
      Something went wrong. Check your network and please try again later.
    
    </div>
  );
};

export default Error;
