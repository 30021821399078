import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import Loading from "../components/Loading";
import NavContent from "../components/NavContent";
import SvgComponent from "../components/SvgComponent";
import { AuthContext } from "../context/AuthContext"; // Adjust the path accordingly
import NavLinksContainer from "../components/NavLinksContainer";
import WhatsappShare from "../components/WhatsappShare";



const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [inputPrompts, setInputPrompts] = useState([]);
  const [events, setEvents] = useState([]);
  const [sseConnection, setSseConnection] = useState(null);
  const [question, setQuestion] = useState([]);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [fetchInput, setFetchInput] = useState([]);
  const [hasRelatedQuestion, setHasRelatedQuestion] = useState([false]);
  const [showRelatedQuestions, setShowRelatedQuestions] = useState(false);
  const [newInputPrompt, setNewInputPrompt] = useState("");
  const textareaRef = useRef(null);
  const chatLogRef = useRef(null);
  const [showFeedback, setShowFeedback] = useState(false);


  const toggleRelatedQuestions = () => {
    setShowRelatedQuestions((prev) => !prev);
  };

  async function callAPI() {
    let firstEmptyContentEncountered = false;
   setShowFeedback(false)
    // Establish new SSE connection with the inputPrompt
    const urlEncoded = `/sse?query=${encodeURIComponent(
      inputPrompt + newInputPrompt
    )}`;
    const newSseConnection = new EventSource(urlEncoded);

    // Handle SSE messages
    newSseConnection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const content = data.content.replace(/\n/g, "<br/>");

      if (!firstEmptyContentEncountered && content === "") {
        // Skip the first empty content
        firstEmptyContentEncountered = true;
      } else if (content !== "") {
        // Add non-empty content to events
        setEvents((prevEvents) => [...prevEvents, content]);
      } else {
        // Close the SSE connection after processing the second empty content
        newSseConnection.close();
        setShowFeedback(true)
        setChatLog([
          ...chatLog,
          {
            chatPrompt: inputPrompt,
            botMessage: data.botResponse,
          },
        ]);
      }
    };

    // Handle SSE errors
    newSseConnection.onerror = (error) => {
      console.error("EventSource failed:", error);
      newSseConnection.close(); // Close the SSE connection in case of an error
    };

    // Set the new SSE connection
    setSseConnection(newSseConnection);
    setInputPrompt("");
    setNewInputPrompt("");

    //  Set responseFromAPI back to false after the fetch request is complete
    setReponseFromAPI(false);
  }

  const handleQuestionClick = (clickedQuestion) => {
    setNewInputPrompt(clickedQuestion);
  };

  useEffect(() => {
    if (newInputPrompt.trim() !== "") {
      console.log(newInputPrompt);
      setEvents([]);
      setQuestion(newInputPrompt);
      setShowRelatedQuestions(false);
      callAPI();
    }
  }, [newInputPrompt]);

  const fetchQuestions = async () => {
    try {
      const response = await fetch("/api/inputmsgs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputPrompt, name: "", email: "" }),
      });
     
      console.log(inputPrompt);
      setFetchInput(inputPrompt);

      const data = await response.json();
      // Set all questions and reset startIndex
      console.log(data)
      setDisplayedQuestions(data.botResponse);
      if (displayedQuestions.length > 0) {
        setHasRelatedQuestion(true);
      } else {
        setHasRelatedQuestion(false);
      }
      setStartIndex(0);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEvents([]);
    setShowRelatedQuestions(false);

    if (sseConnection) {
      sseConnection.close();
    }

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        setFetchInput(inputPrompt);
        setQuestion(inputPrompt);
        fetchQuestions();
        callAPI();
        setInputPrompt("");

        // hide the keyboard in mobile devices
        e.target.querySelector("input").blur();
      }
    }
  };

  const handleShowMore = () => {
    const newStartIndex = startIndex + 5;
    if (newStartIndex < displayedQuestions.length) {
      setStartIndex(newStartIndex);
    }
  };

  const handleShowPrevious = () => {
    const newStartIndex = Math.max(0, startIndex - 5);
    setStartIndex(newStartIndex);
  };
  const questionsToDisplay = displayedQuestions.slice(
    startIndex,
    startIndex + 5
  );

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [events]);

  // Clean up SSE connection on component unmount
  useEffect(() => {
    return () => {
      if (sseConnection) {
        sseConnection.close();
      }
    };
  }, [sseConnection]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to the scroll height
    }
  }, [inputPrompt]);


  

  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    // User is not logged in, show a placeholder avatar or login prompt
    return <div>Please log in to see your avatar.</div>;
  }

  // User is logged in
  const { displayName, photoURL, email } = currentUser;

  return (
    <>
      <div className="mainitem">
        <section className="header1">
          <WhatsappShare></WhatsappShare>
        </section>
        <div className="inputbox_type_ECD">
          <div className="inputPromptWrapper_ECD">
            <div className="inputPrompttTextarea_ECD">
              <textarea
            ref={textareaRef}
                name="inputPrompt"
                className="input_area_ECD"
                
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                placeholder="Type Your Doubts here"
                style={{
                  overflowY: "auto",
              
                  maxHeight: "15vh",
                }}
              ></textarea>
            </div>
            <button
              className="sentbutton_ECD"
              aria-label="form submit"
              type="submit"
              onClick={handleSubmit}
            >
              <svg
                fill="#ADACBF"
                width={15}
                height={20}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#212023"
                strokeWidth={1}
              >
                <title>{"submit form"}</title>
                <path
                  d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                  stroke="none"
                />
              </svg>
            </button>
          </div>

          {question.length > 0 && (
            <div className="Query_Wrapper_ECD">
              <div className="chatPromptMainContainer_ECD">
               
                <div className="chatPromptWrapper_ECD">
                <Avatar className="userSVG">
                      <img id="userphoto" src={photoURL} alt="User Avatar" />
                    </Avatar>
<div className="chatPrompt_ECD">{question}</div>
                </div>
                {questionsToDisplay.length > 0 && (
                  <div className="showRelatedButton_ECD"
                    onClick={toggleRelatedQuestions}
                  >
                    {showRelatedQuestions
                      ? "Hide Related Questions"
                      : "Show Related Questions"}
                  </div>
                )}
              </div>
            </div>
          )}

          {showRelatedQuestions && questionsToDisplay.length > 0 && (
            <div className="relatedSection_ECD">
              <div
                className={`relatedHeading_ECD${
                  questionsToDisplay.length > 0 ? "" : "hide"
                }`}
              >
                Related Questions
              </div>
              <div className="relatedQuestionsWrapper_ECD">
                {questionsToDisplay.map((question) => (
                  <div
                    className="questions_ECD"
                    key={question.QuestionNumber}
                    onClick={() => handleQuestionClick(question.Question)}
                  >
                    <p className="questionRelated_ECD">{question.Question}</p>
                    <p className="subjectName_ECD">{question.Subject}</p>
                 </div>
                ))}
              </div>
              <div className="buttonRelatedWrapper_ECD">
                {startIndex > 0 && (
                  <div className="relatedButton_ECD" onClick={handleShowPrevious}>
                    Show Previous
                  </div>
                )}

                {questionsToDisplay.length === 5 && (
                  <div className="relatedButton_ECD" onClick={handleShowMore}>
                    Show More
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <section className="chatBox">
          {question.length > 0 ? (
            <div className="chatLogWrapper" id="chatLogWrapper">
              <div className="chatLog">
               
                <div className="botMessageMainContainer" ref={chatLogRef}>
                  <div className="botMessageWrapper">
                    <Avatar className="userSVG">
                      <SvgComponent w={41} h={41} />
                    </Avatar>
                    {events.length > 0 ? (
                      <div id="botMessage">
                        <p
                          dangerouslySetInnerHTML={{ __html: events.join("") }}
                        ></p>
                     
                      </div>
                    ) : err ? (
                      <Error err={err} />
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="chatLogWrapper">
              <IntroSection />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Home;
