import React from "react";

const SvgComponent = ({ w, h, stroke }) => {
  return (
    <div >
    
      <img
        src="/Logo.png"
        alt="Buy Me A Coffee Widget"
        id="userphoto"
        style={{
        
          position: "relative",
          left: "4px",
          display: "block",
        }}
      />
   
  
  
    </div>
    
    
  );
};

export default SvgComponent;
